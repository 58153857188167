<template>
  <div class="noprint">
    <div
      class="snackbar rounded shadow-lg px-3 py-2"
      :class="'border-left bg-' + alertColor"
      v-if="message"
    >
      <p class="h5 mb-0 text-white">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      alertColor: "info"
    };
  },
  mounted() {
    this.$EventBus.$on("alert", data => {
      this.message = data.message;
      this.alertColor = data.color ? data.color : "primary";
      setTimeout(() => {
        this.message = "";
      }, 5000);
    });
  }
};
</script>

<style>
.snackbar {
  position: fixed!important;
  top: 0.5rem;
  right: 0.5rem;
  min-width: 200px;
  max-width: 380px;
  height: auto;
  border-width: 15px !important;
  z-index: 10000;
}

.alert-color {
  display: block;
  height: 100%;
}

@media (min-width: 576px) {
  .snackbar {
    max-width: 600px;
  }
}
</style>
